import React from "react";

import ButtonSolid from "../Button/ButtonSolid";

const CTA = ({ heading, headingLevel, text, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`bg-black py-18 md:py-20 ${className}`}>
      <div className="container">
        <div className="text-center md:flex md:items-end md:justify-between md:space-x-6 md:text-left">
          <div className="max-w-[704px]">
            <HeadingTag className="mb-4 text-white">
              {heading || ["Curious What Terms You Can Get?"]}
            </HeadingTag>
            <p className="text-white/80 md:mb-0">
              {text ||
                "Let’s find the right loan for you—contact your Orange County mortgage broker today!"}
            </p>
          </div>
          <div className="grid items-center justify-center gap-y-4 md:flex md:gap-y-0 md:space-x-4">
            <ButtonSolid modal="modal-contact" text="Contact Us" altStyle={2} />
            <ButtonSolid href="/request-rates/" text="Request Rates" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
