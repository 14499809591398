import React, { useState, Fragment } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Listbox, Transition } from "@headlessui/react";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanPrograms";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";


const Page = ({ data }) => {
  const loanPrograms = [
    { name: "Reverse Mortgage", href: "/reverse-mortgage-orange-county/" },
    { name: "Refinance Loans", href: "/refinance-orange-county/" },
    { name: "Purchase Loans", href: "/purchase-orange-county/" },
    { name: "Non-QM Loans", href: "/non-qm-loans/" },
    { name: "Private Loans", href: "/hard-money-loans-orange-county/" },
    { name: "FHA & VA Loans", href: "/fha-va-loans-orange-county/" },
  ];

  const [selectedLoanProgram, setSelectedLoanProgram] = useState(
    loanPrograms[0]
  );

  return (
    <Layout>
      <SearchEngineOptimization
        title="Orange County Mortgage Broker | Nikkael Home Loans"
        description="Nikkael Home Loans is a top Orange County mortgage broker based in Tustin. Our attorney-owned mortgage company serves CA & beyond. Get started here!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-12 overflow-hidden pt-10 md:mb-32 md:pt-36 md:pb-40">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-desktop.jpg"
            alt="We get loans that other people can’t."
            loading="eager"
            layout="fullWidth"
            placeholder="none"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative mb-10 max-w-[520px] md:mb-0">
            <div className="mb-4 font-heading text-mobile-7xl font-extrabold text-typography-heading md:text-7xl md:text-white">
              We get loans that{" "}
              <span className="md:text-primary-500">
                other mortgage brokers can’t.
              </span>
            </div>
            <p className="mb-9 text-xl md:mb-7 md:text-[1.75rem] md:leading-[2.375rem] md:text-white">
              Because we’re more than home loan experts—we’re also attorneys.
            </p>

            <div className="mb-1 text-sm font-medium text-typography-heading/60 md:mb-2 md:text-base md:text-white/60">
              What can we help you with?
            </div>

            <div className="flex w-full">
              <Listbox
                value={selectedLoanProgram}
                onChange={setSelectedLoanProgram}
              >
                <div className="relative w-full">
                  <Listbox.Button className="relative flex h-16 w-full cursor-pointer items-center justify-between space-x-4 rounded-tl rounded-bl border border-gray-300 bg-white px-6 text-left font-normal text-gray-600 no-underline hover:text-gray-600 focus:outline-none md:border-none">
                    <span className="block truncate font-medium">
                      {selectedLoanProgram.name}
                    </span>
                    <i className="fa-regular fa-chevron-down text-3xl"></i>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute left-0 z-10 mt-2 max-h-36 w-[456px] overflow-auto bg-white pt-2 text-left text-sm shadow-lg focus:outline-none">
                      {loanPrograms.map((loanProgram, i) => (
                        <Listbox.Option
                          key={i}
                          value={loanProgram}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-primary-500 text-white"
                                : "text-gray-600"
                            }
                          relative cursor-pointer select-none py-2 px-6`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`${
                                  selected && "font-medium"
                                } block truncate`}
                              >
                                {loanProgram.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-primary-500">
                                  <i className="fa-solid fa-check text-lg"></i>
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <div className="h-16 min-w-[4rem] overflow-hidden rounded-tr rounded-br">
                <Link
                  href={selectedLoanProgram.href}
                  className="flex h-full items-center justify-center bg-primary-500 text-white no-underline hover:bg-primary-900 hover:text-white"
                >
                  <i className="fa-regular fa-arrow-right text-xl"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-mobile.jpg"
            alt="We get loans that other people can’t."
            loading="eager"
            placeholder="none"
            width={778}
          />
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <h1>A Top Orange County Mortgage Broker Serving California</h1>
              <p className="">
                Sometimes finding and qualifying for a home loan can take some
                work, especially when facing a complex situation. Finding
                solutions and removing barriers is what we specialize in at
                Nikkael Home Loans.
              </p>
              <p className="mb-0">
                We're an attorney-owned and operated mortgage brokerage, and as
                attorneys, we have a higher level of fiduciary duty to our
                clients. That high standard and our unique combination of skills
                give us the expertise to navigate roadblocks and get you the
                loan you need.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro.jpg"
                alt="A Top Orange County Mortgage Broker Serving California & Beyond"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <LoanPrograms className="mb-20 md:mb-32" />
      {/* <HomeWorth /> */}
      <ValueProps />
      <About />
      <Testimonials />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-home.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-home.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
